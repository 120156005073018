<div class="section-header-container">
  <h4 class="section-header" data-automation="project-details-label">
    {{ 'project.details' | translate }}
  </h4>
</div>
<div class="details-container" #nodeToAttachMap>
  <div class="details-row">
    <div class="details-half-row created-by-container">
      <span
        class="text-light-color label-fixed-width"
        data-automation="created-by-label"
      >
        {{ 'project.created_by' | translate }}
      </span>
      <div class="details-field-container" id="createdByLabel">
        <span data-automation="created-by-name">
          {{ project.createdBy?.fullName }}
        </span>
      </div>
    </div>
    <div class="details-half-row created-on-container">
      <span
        class="text-light-color label-fixed-width"
        data-automation="project-datetime-label"
      >
        {{ 'project.date_time_created' | translate }}
      </span>
      <div class="details-field-container" id="date-time-label">
        <span data-automation="project-datetime">
          {{ projectCreated | async }}
        </span>
      </div>
    </div>
  </div>
  <div class="details-row">
    <div class="details-half-row">
      <span
        class="text-light-color label-fixed-width"
        data-automation="project-timezone-label"
        [ngClass]="{ 'text-error': isFormError(timezoneFormControl) }"
      >
        {{ 'project.timezone' | translate }}*
      </span>
      <div class="details-field-container">
        <optima-inline-edit
          #timezoneSelect
          ngDefaultControl
          htmlTag="span"
          inputType="select"
          [selectDisplayField]="'displayLabel'"
          [selectValueField]="'timezoneName'"
          [selectIdField]="'id'"
          [selectIdPrefix]="'timezone'"
          [selectEnableFilter]="true"
          [(formControl)]="timezoneFormControl"
          [label]="timezoneService.getTimezoneDisplayLabel(project.id)"
          [fieldToUpdate]="'timezone'"
          [selectArray]="timezoneService.timezones"
          [callback]="updateProject"
        ></optima-inline-edit>
      </div>
    </div>
    <div class="details-half-row">
      <span
        data-automation="project-start-date-label"
        class="text-light-color label-fixed-width"
        [ngClass]="{ 'text-error': isFormError(projectStartDateFormControl) }"
      >
        {{ 'project.start_date' | translate }}
        <img
          class="tooltip-icon"
          matTooltip="{{ 'project.start_date.tooltip' | translate }}"
          src="../../../../../assets/svg-icons/info-circle.svg"
          alt="tooltip-popup"
        />
      </span>
      <div class="details-field-container">
        <optima-inline-edit
          ngDefaultControl
          htmlTag="span"
          inputType="date"
          [(formControl)]="projectStartDateFormControl"
          [label]="projectFormattedStartDate"
          [dateStringFormat]="'MMM dd, yyyy'"
          [fieldToUpdate]="'startDate'"
          [callback]="updateProject"
        ></optima-inline-edit>
      </div>
    </div>
  </div>
  <div class="details-row">
    <div class="details-half-row">
      <span
        class="text-light-color label-fixed-width"
        data-automation="project-location-label"
        [ngClass]="{ 'text-error': isFormError(locationFormControl) }"
      >
        {{ 'project.location' | translate }}*
      </span>
      <div class="details-field-container">
        <optima-inline-edit
          ngDefaultControl
          #mapComponent
          htmlTag="div"
          inputType="map"
          [(formControl)]="locationFormControl"
          [label]="project.location.address"
          [latitudeField]="'latitude'"
          [longitudeField]="'longitude'"
          [fieldToUpdate]="'location'"
          [nodeToAttachMap]="nodeToAttachMap"
          (timezoneChange)="timezoneListener($event)"
          [callback]="updateProject"
        ></optima-inline-edit>
      </div>
    </div>
    <div class="details-half-row">
      <span
        data-automation="project-lifetime-label"
        class="text-light-color label-fixed-width"
        [ngClass]="{ 'text-error': isFormError(projectLifetimeFormControl) }"
      >
        {{ 'project.lifetime' | translate }}
        <img
          class="tooltip-icon"
          matTooltip="{{ 'project.lifetime.tooltip' | translate }}"
          src="../../../../../assets/svg-icons/info-circle.svg"
          alt="tooltip-popup"
        />
      </span>
      <div class="details-field-container">
        <optima-inline-edit
          ngDefaultControl
          htmlTag="span"
          inputType="select"
          [(formControl)]="projectLifetimeFormControl"
          [label]="
            project.projectLifetime ? project.projectLifetime.toString() : ''
          "
          [fieldToUpdate]="'projectLifetime'"
          [selectDisplayField]="'label'"
          [selectValueField]="'value'"
          [selectIdField]="'value'"
          [selectIdPrefix]="'lifetime'"
          [selectArray]="projectLifetimeList"
          [selectEnableFilter]="false"
          [callback]="updateProject"
        ></optima-inline-edit>
      </div>
    </div>
  </div>
</div>
