<div class="app-scenario-create content">
  <div class="flex flex-col justify-between items-center">
    <div class="w-full flex justify-start scenario-header">
      <div class="flex justify-start items-center icon-header">
        <div class="icon-container">
          <mat-icon svgIcon="manage-white" class="create-icon"></mat-icon>
        </div>
        <div>
          <div class="flex justify-between">
            <optima-inline-edit
              ngDefaultControl
              htmlTag="h1"
              inputType="input"
              [(formControl)]="scenarioNameFormControl"
              [label]="scenario.displayLabel"
              [fieldToUpdate]="'displayLabel'"
              [callback]="updateScenarioByForm"
            ></optima-inline-edit>
          </div>
          <h2
            id="parent-title"
            class="parent-title"
            data-automation="createTitle"
          >
            {{ parentProject?.displayLabel || parentProject?.id }}
          </h2>
        </div>
      </div>
      <div
        class="flex justify-start items-center justify-self-end margin-left-auto"
      >
        <button
          id="delete-scenario-button"
          (click)="openDeleteProjectDialog()"
          class="icon-btn primary-btn delete-scenario-button flex items-center"
          matRipple
        >
          <mat-icon svgIcon="delete"></mat-icon>
          <span class="ml-2">{{ 'project.button.delete' | translate }}</span>
        </button>

        <button
          class="flex justify-start items-center summary-button"
          (click)="viewScenario(scenario.id)"
          mat-ripple
          aria-label="View"
          data-automation="view"
        >
          <span class="button-label">
            {{ 'scenario.button.view' | translate }}
          </span>
        </button>
      </div>
    </div>

    <div class="w-full flex justify-start">
      <div class="flex flex-col" style="width: 100%">
        <div class="flex location-container items-center">
          <div class="map-icon mr-3">
            <mat-icon svgIcon="map-pin"></mat-icon>
          </div>
          <div>
            <span class="text-primary-color bold mr-2">
              {{ 'project.location.site' | translate }}:
            </span>
            <span>
              {{
                !!parentProject.location?.address
                  ? parentProject.location?.address
                  : ('project.location.unknown' | translate)
              }}
            </span>
          </div>
        </div>
        <mat-vertical-stepper
          [linear]="isLinear"
          (selectionChange)="stepChange($event)"
          #stepper
        >
          <!--     ENERGY USE     -->

          <mat-step
            [stepControl]="energyUseFormGroup"
            [completed]="!!scenario.loadProfile"
            [state]="!!scenario.loadProfile ? 'done' : 'edit'"
          >
            <form [formGroup]="energyUseFormGroup">
              <ng-template matStepLabel>
                {{ 'scenario.energy_use' | translate }}
              </ng-template>
              <optima-loader
                id="energy-use-loader"
                [translateString]="'loader.message.energy_use'"
              ></optima-loader>
              <optima-energy-use
                [scenario]="scenario"
                [loadProfile]="scenario.loadProfile"
                [loadProfiles]="loadProfiles"
                (scenarioChange)="scenarioChange($event)"
              ></optima-energy-use>
              <optima-site-constraint-form
                *ngIf="scenario.loadProfile?.json"
                [(scenario)]="scenario"
                [minDynamicInputs]="minLoadSiteConstraintInputs"
                [maxDynamicInputs]="maxLoadSiteConstraintInputs"
              ></optima-site-constraint-form>
              <div>
                <button mat-stroked-button matStepperNext color="primary">
                  {{ 'project.button.next' | translate }}
                </button>
              </div>
            </form>
          </mat-step>

          <!--     TARIFF     -->

          <mat-step
            [stepControl]="tariffDataFormGroup"
            [completed]="!!scenario.currentTariff"
            [state]="
              !!(scenario.currentTariff && scenario.switchTariff)
                ? 'done'
                : stepper.selectedIndex === 1
                  ? 'edit'
                  : 'tariff-unactive'
            "
          >
            <optima-loader
              [translateString]="'loader.message.tariff'"
            ></optima-loader>
            <form [formGroup]="tariffDataFormGroup">
              <ng-template matStepLabel>
                {{ 'scenario.tariff' | translate }}
              </ng-template>
              <!-- TODO: to refactor better moving all html into the ess component like optima-grid -->
              <optima-tariff
                [scenario]="scenario"
                [storedDynamicRates]="storedDynamicRateList"
                (tariffChange)="tariffChange($event)"
              ></optima-tariff>
              <div
                class="flex items-center mt-8 mb-8 pointer tariff-options-header"
                (click)="showTariffOptions = !showTariffOptions"
              >
                <img
                  src="../../../../assets/svg-icons/arrow.svg"
                  alt="arrow"
                  class="mr-4"
                  [class]="
                    !showTariffOptions
                      ? 'tariff-option-arrow-90'
                      : 'tariff-option-arrow-180'
                  "
                />
                <h5>
                  {{ 'scenario.tariff.advanced' | translate }}
                </h5>
              </div>
              <optima-tariff-advanced-options
                *ngIf="showTariffOptions"
                [scenario]="scenario"
                [@inOut2]
              ></optima-tariff-advanced-options>
              <div class="mt-4">
                <button mat-stroked-button matStepperPrevious color="primary">
                  {{ 'project.button.previous' | translate }}
                </button>
                <button
                  mat-stroked-button
                  color="primary"
                  class="capitalize"
                  (click)="viewScenario(scenario.id)"
                >
                  {{ 'scenario.button.view' | translate }}
                </button>
              </div>
            </form>
          </mat-step>

          <!--     SOLAR     -->

          <mat-step
            [stepControl]="addSolarPVFormGroup"
            [completed]="scenario?.options?.solarPv && !!scenario.pvloadId"
            [state]="
              scenario?.options?.solarPv && !!scenario.pvloadProfile
                ? 'done'
                : stepper.selectedIndex === 2
                  ? 'edit'
                  : 'optional'
            "
          >
            <form [formGroup]="addSolarPVFormGroup">
              <ng-template matStepLabel>
                <div class="flex items-center">
                  <span
                    *ngIf="scenario?.options?.solarPv; else addPvLoadLabel"
                    class="mr-6"
                  >
                    {{ 'scenario.form.solar' | translate }}
                  </span>
                  <mat-slide-toggle
                    [checked]="scenario?.options?.solarPv"
                    (change)="toggleSlide($event, 'solar')"
                  ></mat-slide-toggle>
                </div>
              </ng-template>
              <div class="items-center" [hidden]="!scenario.options?.solarPv">
                <div class="component-container">
                  <optima-loader
                    [translateString]="'loader.message.solar'"
                  ></optima-loader>
                  <optima-solar-pv
                    data-automation="solar-pv-form-element"
                    [scenario]="scenario"
                    [loadProfiles]="pvLoadProfiles"
                    (scenarioChange)="scenarioChange($event)"
                  ></optima-solar-pv>
                </div>
                <div class="mt-4">
                  <button mat-stroked-button matStepperPrevious color="primary">
                    {{ 'project.button.previous' | translate }}
                  </button>
                  <button mat-stroked-button matStepperNext color="primary">
                    {{ 'project.button.next' | translate }}
                  </button>
                </div>
              </div>
            </form>
          </mat-step>

          <!--     ESS STORAGE     -->

          <mat-step
            [stepControl]="addEnergyStorageFormGroup"
            [completed]="
              isEssSectionCompleted && scenario?.options?.energyStorage
            "
            [state]="
              isEssSectionCompleted && scenario?.options?.energyStorage
                ? 'done'
                : stepper.selectedIndex === 3
                  ? 'edit'
                  : 'optional'
            "
          >
            <form [formGroup]="addEnergyStorageFormGroup">
              <ng-template matStepLabel>
                <div class="flex items-center">
                  <span
                    *ngIf="
                      scenario?.options?.energyStorage;
                      else addEnergyStorageLabel
                    "
                    class="mr-6"
                  >
                    {{ 'scenario.form.energy_storage' | translate }}
                  </span>
                  <mat-slide-toggle
                    [checked]="scenario?.options?.energyStorage"
                    (change)="toggleSlide($event, 'ess')"
                  ></mat-slide-toggle>
                </div>
              </ng-template>
              <!-- TODO: to refactor better moving all html into the ess component like optima-grid -->
              <div class="items-center" *ngIf="scenario.options?.energyStorage">
                <div class="ess-container">
                  <div class="ess-header mb-8">
                    <h5 class="mb-2">
                      {{ 'scenario.form.energy_storage.select' | translate }}
                    </h5>
                    <div class="flex">
                      <span class="description">
                        {{
                          'scenario.form.energy_storage.link.description'
                            | translate
                        }}
                      </span>
                      <span class="ml-1">
                        <a
                          class="pointer"
                          target="_blank"
                          href="https://enelcom.sharepoint.com/:x:/s/Hardware_product_management/ERO_2AdELw9Mvb3sTjeZ0egBKwZIpvt5yvU5L01HrK66Eg"
                        >
                          {{
                            'scenario.form.energy_storage.link.definition'
                              | translate
                          }}
                        </a>
                      </span>
                    </div>
                  </div>
                  <optima-loader id="ess-loader"></optima-loader>
                  <optima-ess-table
                    [(scenario)]="scenario"
                    [bess]="bess"
                    (completedEmitter)="essCompleted($event)"
                  ></optima-ess-table>
                  <div
                    class="flex items-center mt-8 mb-8 pointer ess-options-header"
                    (click)="showEssOptions = !showEssOptions"
                    data-automation="ess-storage-advanced-tab"
                  >
                    <img
                      src="../../../../assets/svg-icons/arrow.svg"
                      alt="arrow"
                      class="mr-4"
                      [class]="
                        !showEssOptions
                          ? 'ess-option-arrow-90'
                          : 'ess-option-arrow-180'
                      "
                    />
                    <h5>
                      {{ 'scenario.ess' | translate }}
                    </h5>
                  </div>
                  <optima-ess-advanced-options
                    *ngIf="showEssOptions"
                    [scenario]="scenario"
                  ></optima-ess-advanced-options>
                </div>
                <div class="mt-4">
                  <button mat-stroked-button matStepperPrevious color="primary">
                    {{ 'project.button.previous' | translate }}
                  </button>
                  <button mat-stroked-button matStepperNext color="primary">
                    {{ 'project.button.next' | translate }}
                  </button>
                </div>
              </div>
            </form>
          </mat-step>

          <!--     GRID     -->

          <mat-step
            [stepControl]="addGridServicesFormGroup"
            [completed]="
              scenario?.options?.gridService
              && (!!scenario?.programs?.standard?.length || !!scenario?.programs?.custom?.length)
            "
            [state]="
              scenario?.options?.gridService
                ? 'done'
                : stepper.selectedIndex === 4
                  ? 'edit'
                  : 'optional'
            "
          >
            <form [formGroup]="addGridServicesFormGroup">
              <ng-template matStepLabel>
                <div class="flex items-center">
                  <span
                    *ngIf="scenario?.options?.gridService; else addGridLabel"
                    class="mr-6"
                  >
                    {{ 'scenario.form.grid_services' | translate }}
                  </span>
                  <mat-slide-toggle
                    [checked]="scenario?.options?.gridService"
                    (change)="toggleSlide($event, 'grid')"
                  ></mat-slide-toggle>
                </div>
              </ng-template>
              <div class="items-center" *ngIf="scenario.options?.gridService">
                <div class="component-container">
                  <optima-loader></optima-loader>
                  <optima-grid [(scenario)]="scenario"></optima-grid>
                </div>
                <div class="mt-4">
                  <button mat-stroked-button matStepperPrevious color="primary">
                    {{ 'project.button.previous' | translate }}
                  </button>
                  <button mat-stroked-button matStepperNext color="primary">
                    {{ 'project.button.next' | translate }}
                  </button>
                </div>
              </div>
            </form>
          </mat-step>

          <!--     FORECASTED     -->

          <mat-step
            [stepControl]="addForecastedFormGroup"
            [completed]="
              scenario?.options?.forecast &&
              (scenario?.forecastPvId || scenario?.forecastLoadId)
            "
            [state]="
              scenario?.options?.forecast
                ? 'done'
                : stepper.selectedIndex === 5
                  ? 'edit'
                  : 'optional'
            "
          >
            <form [formGroup]="addForecastedFormGroup">
              <ng-template matStepLabel>
                <div class="flex items-center">
                  <span
                    *ngIf="scenario?.options?.forecast; else addForecasted"
                    class="mr-6"
                  >
                    {{ 'scenario.form.forecasted' | translate }}
                  </span>
                  <mat-slide-toggle
                    data-automation="forecast-toggle"
                    [checked]="scenario?.options?.forecast"
                    (change)="toggleSlide($event, 'forecast')"
                    [disabled]="isForecastDisabled()"
                    matTooltip="{{ getForecastDisabledTooltip() }}"
                    [matTooltipDisabled]="
                      !forecastService.isForecastedStepDisabled(scenario)
                    "
                  ></mat-slide-toggle>
                </div>
              </ng-template>
              <div class="items-center" *ngIf="scenario.options?.forecast">
                <div class="component-container">
                  <optima-forecasted
                    [scenario]="scenario"
                    (forecastChange)="forecastChange($event)"
                    (forecastRemoved)="forecastRemoved($event)"
                    [energyForecasts]="energyForecasts"
                    [solarForecasts]="solarForecasts"
                  ></optima-forecasted>
                </div>
                <div class="mt-8">
                  <button mat-stroked-button matStepperPrevious color="primary">
                    {{ 'project.button.previous' | translate }}
                  </button>
                </div>
              </div>
            </form>
          </mat-step>

          <!--     ICON TEMPLATES     -->

          <ng-template matStepperIcon="optional">
            <mat-icon style="color: #f0f0f0">add</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="tariff-unactive">
            <mat-icon style="color: #f0f0f0" svgIcon="tariff"></mat-icon>
          </ng-template>

          <ng-template matStepperIcon="edit" let-index="index">
            <ng-container [ngSwitch]="index">
              <mat-icon *ngSwitchCase="0" svgIcon="load-profile"></mat-icon>
              <mat-icon *ngSwitchCase="1" svgIcon="tariff"></mat-icon>
              <mat-icon *ngSwitchCase="2" svgIcon="solar-pv-white"></mat-icon>
              <mat-icon *ngSwitchCase="3" svgIcon="energy-storage"></mat-icon>
              <mat-icon *ngSwitchCase="4" svgIcon="grid-white"></mat-icon>
              <mat-icon *ngSwitchDefault style="color: #f0f0f0">edit</mat-icon>
            </ng-container>
          </ng-template>
        </mat-vertical-stepper>

        <button
          mat-stroked-button
          color="primary"
          class="capitalize w-fit ml-8 mb-8 mt-4"
          (click)="viewScenario(scenario.id)"
        >
          {{ 'scenario.button.view' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #addEnergyStorageLabel>
  <span class="mr-6">{{ 'scenario.form.add_energy_storage' | translate }}</span>
</ng-template>

<ng-template #addGridLabel>
  <span class="mr-6">{{ 'scenario.form.add_grid_services' | translate }}</span>
</ng-template>

<ng-template #addPvLoadLabel>
  <span class="mr-6">{{ 'scenario.form.add_solar' | translate }}</span>
</ng-template>

<ng-template #addForecasted>
  <span class="mr-6">
    {{ 'scenario.form.add_forecasted_data' | translate }}
  </span>
</ng-template>
