<div class="forecasted-solar-container">
  <h2>
    {{ 'scenario.form.forecasted.solar' | translate }}
    <span class="text-warn">*</span>
    <img
      class="info-circle"
      matTooltip="{{ 'scenario.form.forecast.solar.tooltip' | translate }}"
      src="../../../../../../../assets/svg-icons/info-circle.svg"
      alt="info-circle"
    />
  </h2>

  <table class="classic-table mt-5">
    <tr>
      <th>{{ 'scenario.form.forecasted.solar.system_size' | translate }}</th>
      <th>{{ 'scenario.form.forecasted.solar.historical' | translate }}</th>
      <th>{{ 'scenario.form.forecasted.solar.forecasted' | translate }}</th>
    </tr>
    <tr>
      <td>{{ scenario.pvloadProfile.solarPvSize }}</td>
      <td>{{ scenario.pvloadProfile.filename }}</td>
      <td>
        <span *ngIf="scenario.forecastPvId">
          {{ scenario.forecastPvLoadProfile.filename }}
          <mat-icon
            svgIcon="clear-pink"
            class="pointer"
            (click)="updateForecast(null)"
          ></mat-icon>
        </span>
        <button
          *ngIf="!scenario.forecastPvId"
          mat-stroked-button
          color="primary"
          class="add-cancel w-fit flex items-center"
          (click)="isAdding = !isAdding"
        >
          <img
            ngSrc="../../../../../../../assets/svg-icons/plus-alone.svg"
            alt="plus"
            height="9"
            width="9"
          />
          {{
            (isAdding
              ? 'scenario.form.forecasted.solar.cancel_file'
              : 'scenario.form.forecasted.solar.add_file'
            ) | translate
          }}
        </button>
      </td>
    </tr>
    <tr *ngIf="!scenario.forecastPvId && isAdding">
      <td colspan="3" class="uploader-cell">
        <div
          class="flex flex-row justify-start"
          [ngClass]="
            scenario.forecastPvId &&
            scenario.forecastPvLoadProfile?.status === 'COMPLETED'
              ? 'items-start'
              : 'items-stretch'
          "
        >
          <div
            class="flex flex-col existing-forecast-container"
            *ngIf="
              !scenario.forecastPvId ||
              scenario.forecastPvLoadProfile?.status !== 'COMPLETED'
            "
          >
            <p class="forecasts-title">
              {{ 'scenario.energy_use.search_profile' | translate }}
            </p>
            <div class="flex items-center search-container">
              <mat-icon svgIcon="search-new" class="icon-search"></mat-icon>
              <input
                class="search-input"
                placeholder="{{ 'project.search' | translate }}"
                [disabled]="!solarForecasts?.length"
                #searchInput
              />
            </div>
            <div class="forecasts-container" *ngIf="solarForecasts?.length">
              <div
                class="flex items-center"
                *ngFor="let forecast of filteredForecasts"
                (click)="updateForecast(forecast)"
              >
                <input type="radio" name="profile" />
                <label class="solar-forecast-text {">
                  {{ forecast.filename | trimFileExtension | stringTruncate }}
                </label>
              </div>
            </div>
            <div class="forecasts-container" *ngIf="!solarForecasts?.length">
              <p class="text-light-color">
                {{ 'scenario.energy_use.no_files_found' | translate }}
              </p>
            </div>
          </div>
          <div
            class="uploader-container"
            [ngClass]="{
              'uploader-container-width':
                !scenario.forecastPvId ||
                scenario.forecastPvLoadProfile?.status !== 'COMPLETED',
            }"
          >
            <optima-uploader
              #uploaderSolarForecast
              title="{{ 'uploader.solar_forecast.title' | translate }}"
              subTitle="{{ 'uploader.energy_forecast.subtitle' | translate }}"
              [url]="scenarioService.apiUrl + postForecastUrl"
              [createMethod]="'post'"
              [accept]="'.csv'"
              [formDataParameters]="formDataParameters"
              [object]="scenario.forecastPvLoadProfile"
              (objectChange)="updateForecastChunk($event)"
              [idField]="'id'"
              [labelField]="'filename'"
              [fileJustify]="'justify-start'"
              [chunk]="true"
            ></optima-uploader>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>
