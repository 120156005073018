<h5 class="mb-8 mt-10">
  {{ 'scenario.form.forecast.energy' | translate }}
  <span class="text-warn">*</span>
  <img
    class="info-circle"
    matTooltip="{{ 'scenario.form.forecast.energy.tooltip' | translate }}"
    src="../../../../../../../assets/svg-icons/info-circle.svg"
    alt="info-circle"
  />
</h5>

<div
  class="flex flex-row justify-start"
  [ngClass]="
    scenario.forecastLoadId &&
    scenario.forecastLoadProfile?.status === 'COMPLETED'
      ? 'items-start'
      : 'items-stretch'
  "
>
  <div
    class="flex flex-col existing-forecast-container"
    *ngIf="
      !scenario.forecastLoadId ||
      scenario.forecastLoadProfile?.status !== 'COMPLETED'
    "
  >
    <p class="forecasts-title">
      {{ 'scenario.energy_use.search_profile' | translate }}
    </p>
    <div class="flex items-center search-container">
      <mat-icon svgIcon="search-new" class="icon-search"></mat-icon>
      <input
        class="search-input"
        placeholder="{{ 'project.search' | translate }}"
        [disabled]="!energyForecasts?.length"
        #searchInput
      />
    </div>
    <div class="forecasts-container" *ngIf="energyForecasts?.length">
      <div
        class="flex items-center"
        *ngFor="let forecast of filteredForecasts"
        (click)="updateForecast(forecast)"
      >
        <input type="radio" name="profile" />
        <label class="load-forecast-text">
          {{ forecast.filename | trimFileExtension | stringTruncate }}
        </label>
      </div>
    </div>
    <div class="forecasts-container" *ngIf="!energyForecasts?.length">
      <p class="text-light-color">
        {{ 'scenario.energy_use.no_files_found' | translate }}
      </p>
    </div>
  </div>
  <div
    class="uploader-container"
    [ngClass]="{
      'uploader-container-width': !scenario.forecastLoadId,
    }"
  >
    <optima-uploader
      #uploaderEnergyForecast
      title="{{ 'uploader.energy_forecast.title' | translate }}"
      subTitle="{{ 'uploader.energy_forecast.subtitle' | translate }}"
      [url]="scenarioService.apiUrl + postForecastUrl"
      [createMethod]="'post'"
      [accept]="'.csv'"
      [formDataParameters]="formDataParameters"
      [object]="scenario.forecastLoadProfile"
      (objectChange)="updateForecastChunk($event)"
      [idField]="'id'"
      [labelField]="'filename'"
      [fileJustify]="'justify-start'"
      [chunk]="true"
    ></optima-uploader>
  </div>
</div>
