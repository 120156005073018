<div class="items-center">
  <div
    class="uploader-container"
    [ngClass]="{ 'uploader-container-width': !scenario.currentTariff?.stats }"
  >
    <h5 class="mb-8">
      {{ 'scenario.current_tariff' | translate }}
    </h5>
    <optima-uploader
      #uploaderTariff
      title="{{ 'uploader.tariff.title' | translate }}"
      fileLabel="{{ 'uploader.tariff.file_label' | translate }}"
      [url]="scenarioService.apiUrl + '/tariffs'"
      [createMethod]="'put'"
      [accept]="'.csv'"
      [formDataParameters]="currentTariffFormDataKeyValue"
      [object]="scenario.currentTariff"
      (objectChange)="updateCurrentTariff($event)"
      [idField]="'id'"
      [labelField]="'filename'"
    ></optima-uploader>
    <div *ngIf="scenario.currentTariff?.stats?.length" class="mb-8">
      <optima-tariff-table
        [stats]="scenario.currentTariff.stats"
        [projectId]="scenario.parentId"
        class="table-container"
      ></optima-tariff-table>
    </div>
    <div
      *ngIf="
        scenario.currentTariff?.filename && !scenario.loadProfile?.filename
      "
    >
      <span>{{ 'scenario.tariff.no_lp_no_data' | translate }}</span>
    </div>
  </div>
  <ng-container *ngIf="scenario.loadProfile && scenario.currentTariff?.stats">
    <div class="dynamic-rates-checkbox-container">
      <mat-checkbox
        data-automation="current-tariff-dynamic-checkbox"
        [(ngModel)]="scenario.tariffParameters.dynamicBefore"
        (ngModelChange)="updateTariffParam()"
      >
        {{ 'scenario.tariff.add_dynamic' | translate }}
      </mat-checkbox>
      <img
        class="info-circle"
        matTooltip="{{
          'scenario.tariff.dynamic.checkbox.tooltip' | translate
        }}"
        src="../../../../../../assets/svg-icons/info-circle.svg"
        alt="info-circle"
      />
    </div>
    <div
      *ngIf="scenario.tariffParameters.dynamicBefore"
      class="dynamic-rate-container"
    >
      <optima-dynamic-rates
        [scenario]="scenario"
        [tariffType]="'current'"
        [storedRates]="storedDynamicRates"
        (tariffChange)="dynamicRateChange($event)"
        data-automation="current-tariff-dynamic-rate"
      ></optima-dynamic-rates>
    </div>
  </ng-container>

  <mat-slide-toggle
    *ngIf="scenario?.currentTariff?.stats"
    [checked]="scenario?.options?.tariffSwitch"
    (change)="toggleSlide($event)"
    class="mb-8"
  >
    <h5>
      {{ 'scenario.switch_tariff' | translate }}
    </h5>
  </mat-slide-toggle>

  <div
    class="uploader-container"
    *ngIf="scenario.options?.tariffSwitch && scenario.currentTariff?.stats"
    [ngClass]="{ 'uploader-container-width': !scenario.switchTariff?.stats }"
  >
    <optima-uploader
      #uploaderTariffSwitch
      title="{{ 'uploader.tariff.title' | translate }}"
      fileLabel="{{ 'uploader.tariff_switch.file_label' | translate }}"
      [url]="scenarioService.apiUrl + '/tariffs'"
      [createMethod]="'put'"
      [accept]="'.csv'"
      [formDataParameters]="switchTariffFormDataKeyValue"
      [object]="scenario.switchTariff"
      (objectChange)="updateSwitchTariff($event)"
      [idField]="'id'"
      [labelField]="'filename'"
    ></optima-uploader>
    <div *ngIf="scenario.switchTariff?.stats?.length">
      <optima-tariff-table
        [stats]="scenario.switchTariff?.stats"
        [projectId]="scenario.parentId"
        class="table-container"
      ></optima-tariff-table>
    </div>
    <div
      *ngIf="scenario.switchTariff?.filename && !scenario.loadProfile?.filename"
    >
      <span>{{ 'scenario.tariff.no_lp_no_data' | translate }}</span>
    </div>
  </div>
  <ng-container
    *ngIf="
      scenario.loadProfile &&
      scenario.options?.tariffSwitch &&
      scenario.currentTariff?.stats &&
      scenario.switchTariff?.stats
    "
  >
    <div class="dynamic-rates-checkbox-container">
      <mat-checkbox
        data-automation="switch-tariff-dynamic-checkbox"
        [(ngModel)]="scenario.tariffParameters.dynamicAfter"
        (ngModelChange)="updateTariffParam()"
      >
        {{ 'scenario.tariff.add_dynamic' | translate }}
      </mat-checkbox>
      <img
        class="info-circle"
        matTooltip="{{
          'scenario.tariff.dynamic.checkbox.tooltip' | translate
        }}"
        src="../../../../../../assets/svg-icons/info-circle.svg"
        alt="info-circle"
      />
    </div>
    <div
      *ngIf="scenario.tariffParameters.dynamicAfter"
      class="dynamic-rate-container"
    >
      <optima-dynamic-rates
        [scenario]="scenario"
        [tariffType]="'switch'"
        [storedRates]="storedDynamicRates"
        (tariffChange)="dynamicRateChange($event)"
      ></optima-dynamic-rates>
    </div>
  </ng-container>
</div>
