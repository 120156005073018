<div
  class="section-header-container"
  data-automation="scenario-header-container"
>
  <h4 class="section-header" data-automation="scenario-header">
    {{ 'project.scenarios' | translate }}
  </h4>
</div>

<div class="container">
  <div
    class="scenario-not-found"
    id="scenario-not-found"
    *ngIf="!scenarios?.length"
  >
    {{ 'scenario.not_found' | translate }}
  </div>

  <button
    class="icon-btn primary-btn create-button"
    data-automation="create-scenario-button"
    mat-ripple
    (click)="createScenario()"
    [disabled]="projectInvalid"
  >
    <span>{{ 'project.create_scenario' | translate }}</span>
  </button>

  <div
    *ngIf="scenarios?.length"
    data-automation="project-scenario-table-container"
  >
    <table
      data-automation="project-scenario-table"
      mat-table
      matSort
      [dataSource]="dataSource"
      (matSortChange)="sortData($event)"
    >
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="status">
          {{ 'scenario.tableheading.status' | translate }}
        </th>
        <td mat-cell *matCellDef="let scenario">
          <ng-container [ngSwitch]="scenario['runState']">
            <ng-container *ngSwitchCase="'STARTED'">
              <img
                [src]="imagesPath + 'oval-yellow-hollow.svg'"
                class="images"
                alt="yellow"
                matTooltip="{{ 'scenario.results.status.yellow' | translate }}"
              />
            </ng-container>
            <ng-container *ngSwitchCase="'COMPLETED'">
              <img
                [src]="imagesPath + 'oval-green.svg'"
                class="images"
                alt="green"
                matTooltip="{{ 'scenario.results.status.green' | translate }}"
              />
            </ng-container>
            <ng-container *ngSwitchCase="'ERROR'">
              <img
                [src]="imagesPath + 'oval-red.svg'"
                class="images"
                alt="red"
                matTooltip="{{ 'scenario.results.status.red' | translate }}"
              />
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="icons">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'scenario.tableheading.scenario.value_streams' | translate }}
        </th>
        <td mat-cell *matCellDef="let scenario">
          <div class="mr-4">
            <img
              [src]="
                showTariffIcon(scenario)
                  ? svgPath + 'switch.svg'
                  : svgPath + 'switch-grey.svg'
              "
              alt="{{ 'scenario.value_streams.switch' | translate }}"
              matTooltip="{{ 'scenario.value_streams.switch' | translate }}"
              class="images mr-2"
            />
            <img
              [src]="
                showEnergyStorageIcon(scenario)
                  ? svgPath + 'battery.svg'
                  : svgPath + 'battery-grey.svg'
              "
              alt="{{ 'scenario.value_streams.energy' | translate }}"
              matTooltip="{{ 'scenario.value_streams.energy' | translate }}"
              class="images mr-2"
            />
            <img
              [src]="
                showSolarIcon(scenario)
                  ? svgPath + 'solar.svg'
                  : svgPath + 'solar-grey.svg'
              "
              alt="{{ 'scenario.value_streams.solar' | translate }}"
              matTooltip="{{ 'scenario.value_streams.solar' | translate }}"
              class="images mr-2"
            />
            <img
              [src]="
                showGridIcon(scenario)
                  ? svgPath + 'grid.svg'
                  : svgPath + 'grid-grey.svg'
              "
              alt="{{ 'scenario.value_streams.grid' | translate }}"
              matTooltip="{{ 'scenario.value_streams.grid' | translate }}"
              class="images"
            />
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
          {{ 'scenario.tableheading.scenario.name' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let scenario"
          data-automation="scenarioTableName"
        >
          <a (click)="goToScenario(scenario)">{{ scenario.displayLabel }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="updatedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedBy">
          {{ 'scenario.tableheading.updated_by' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let scenario"
          data-automation="scenarioTableUpdatedBy"
        >
          {{ scenario.updatedBy?.fullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastUpdatedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="lastUpdatedDate">
          {{ 'scenario.tableheading.last_updated' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let scenario"
          data-automation="scenarioTableLastUpdatedDate"
        >
          {{ scenariosLastSaved.get(scenario.id) | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'scenario.tableheading.action' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let scenario"
          data-automation="scenarioTableActions"
        >
          <img
            [src]="imagesPath + 'edit.svg'"
            alt="{{ 'scenario.edit_button.alt' | translate }}"
            class="images pointer mr-2"
            matTooltip="{{ 'scenario.edit_button.alt' | translate }}"
            (click)="editScenario(scenario.id)"
          />
          <img
            [src]="imagesPath + 'clone.svg'"
            alt="{{ 'scenario.clone_button.alt' | translate }}"
            class="images pointer mr-2"
            matTooltip="{{ 'scenario.clone_button.alt' | translate }}"
            (click)="cloneScenario(scenario.id)"
          />
          <img
            [src]="imagesPath + 'delete.svg'"
            alt="{{ 'scenario.delete_button.alt' | translate }}"
            class="images pointer"
            matTooltip="{{ 'scenario.delete_button.alt' | translate }}"
            (click)="deleteScenario(scenario)"
          />
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let scenario; columns: displayedColumns"
        id="{{ 'scenarioTable-' + scenario.id }}"
      ></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page of users"
      data-automation="scenario-table-paginator"
    ></mat-paginator>
  </div>
</div>
