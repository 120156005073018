<optima-global-alert></optima-global-alert>

<global-nav #nav [navUrl]="navUrl"></global-nav>

<mat-sidenav-container (backdropClick)="sidenavOpen = false">
  <mat-sidenav
    #sidenav
    class="sidenav-container"
    fixedInViewport="true"
    fixedTopGap="60"
    [mode]="isLargeScreen() ? 'side' : 'over'"
    [opened]="sidenavOpen"
  >
    <div class="sidenav-header">
      <div class="flex justify-between items-center">
        <h1 class="page-title" data-automation="listTitle">
          {{ 'APP.TITLE' | translate }}
        </h1>
        <button
          mat-icon-button
          color="accent"
          aria-label="Menu"
          class="toggle-sidenav flex justify-center items-center"
          data-automation="toggle-sidenav"
          (click)="toggleSidenav()"
        >
          <mat-icon>double_arrow</mat-icon>
        </button>
      </div>
      <div class="flex items-center">
        <mat-icon svgIcon="search-new" class="icon-search"></mat-icon>
        <input
          class="search-input"
          placeholder="{{ 'project.search.project_and_scenario' | translate }}"
          [disabled]="disabledInput"
          #searchInput
        />
      </div>
    </div>
    <div class="sidenav-component-container" data-automation="tree-container">
      <optima-tree></optima-tree>
    </div>
  </mat-sidenav>

  <mat-sidenav-content
    data-automation="sidenav-content"
    [class.sidenav-content-stretch]="!sidenavOpen"
  >
    <div data-automation="scrollable-container" class="full overflow-auto">
      <div data-automation="main-container" class="main-container">
        <optima-loader id="global"></optima-loader>
        <router-outlet></router-outlet>
        <ngx-global-contact-footer></ngx-global-contact-footer>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<img
  hidden
  class="image-to-load"
  *ngFor="let image of images"
  [alt]="image"
  [src]="image"
  (load)="load()"
/>
