<div class="flex flex-col justify-start sidenav-body" [hidden]="!loaded">
  <div
    class="table-container"
    [style.height]="treeHeight"
    [hidden]="!areProjects"
    data-automation="tree-selector"
  >
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="tree"
    >
      <mat-tree-node
        *matTreeNodeDef="let node"
        (click)="clicked(node)"
        [ngClass]="{ 'selected-node': checkNode(node) }"
        [id]="node.id + node.type"
        [hidden]="!node.label"
      >
        {{ node.label | stringTruncate: 30 }}
      </mat-tree-node>
      <mat-nested-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        (click)="clicked(node)"
        [ngClass]="{ 'selected-node': checkNode(node) }"
        [id]="node.id + node.type"
        [hidden]="!node.label"
      >
        <div class="mat-tree-node">
          {{ node.label | stringTruncate: 30 }}
          <button
            mat-icon-button
            matTreeNodeToggle
            (click)="toggleButton(node)"
            [attr.aria-label]="'Toggle ' + node.label"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'
              }}
            </mat-icon>
          </button>
        </div>
        <div
          [class.tree-invisible]="!treeControl.isExpanded(node)"
          role="group"
        >
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
  <div class="list-empty" [hidden]="areProjects">
    {{ 'project.list.none_configured' | translate }}
  </div>

  <div id="create-button-container" class="flex justify-end mt-4">
    <button
      id="create-button"
      (click)="createProject()"
      class="icon-btn primary-btn"
      data-automation="create-project-button"
      matRipple
    >
      <span>{{ 'project.list.button.create_project' | translate }}</span>
    </button>
  </div>
</div>
<div class="spinner-container" [hidden]="loaded">
  <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
</div>
