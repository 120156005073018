<div class="flex location-container items-center">
  <div class="map-icon mr-3">
    <mat-icon svgIcon="map-pin"></mat-icon>
  </div>
  <div>
    <span class="text-primary-color bold mr-2">
      {{ 'project.location.site' | translate }}:
    </span>
    <span>
      {{
        !!project?.location?.address
          ? project.location.address
          : ('project.location.unknown' | translate)
      }}
    </span>
  </div>
</div>

<div class="scenario-detail-section">
  <div>
    <div class="section-header-container">
      <h4 class="section-header">
        {{ 'scenario.energy_use' | translate }}
      </h4>
    </div>
    <div
      class="flex flex-col items-center subsection-container"
      *ngIf="
        scenario.loadProfile?.stats && scenario.loadProfile?.json;
        else emptyEnergyUse
      "
    >
      <div class="flex justify-self-end items-center margin-left-auto"
           *ngIf="!scenario.forecastLoadId || !scenario.options?.forecast">
        <div class="flex flex-col mr-4">
          <span class="mb-1">
            {{ 'scenario.summary.energy_use.file_name.label' | translate }}:
          </span>
        </div>
        <div class="flex flex-col">
          <span class="mb-1" data-automation="load-profile-filename">
            {{ scenario.loadProfile.filename }}
          </span>
        </div>
      </div>

      <div class="flex justify-self-end items-center margin-left-auto"
           *ngIf="scenario.forecastLoadId && scenario.options?.forecast">
        <div class="flex flex-col mr-4">
          <span class="mb-1">
            {{ 'scenario.summary.historical_energy_use.file_name.label' | translate }}:
          </span>
          <span>
            {{ 'scenario.summary.forecasted_energy_use.file_name.label' | translate }}:
          </span>
        </div>
        <div class="flex flex-col">
          <span class="mb-1" data-automation="load-profile-filename">
            {{ scenario.loadProfile.filename }}
          </span>
          <span data-automation="forecasted-load-profile-filename">
            {{ scenario.forecastLoadProfile.filename }}
          </span>
        </div>
      </div>

      <optima-multi-load-profile-chart
        [projectId]="scenario.parentId"
        [beforeJson]="scenario.loadProfile.json"
        [mode]="'single'"
        [beforeColor]="'#C2185B'"
        [scenario]="scenario"
      ></optima-multi-load-profile-chart>
      <optima-multi-load-profile-stats-table
        [beforeStats]="scenario.loadProfile.stats"
        [mode]="'single'"
        class="table-container"
      ></optima-multi-load-profile-stats-table>
      <div class="w-full">
        <div class="section-header-container">
          <h4 class="section-header">
            <img
              src="/assets/svg-icons/building1.svg"
              alt=""
              class="site-constraint-icon"
            />
            <span>{{ 'scenario.site_constraint' | translate }}</span>
          </h4>
        </div>
        <optima-site-constraint
          *ngIf="
            scenario.minLoadSiteParameters?.id >= 0 ||
            scenario.maxLoadSiteParameters?.id >= 0
          "
          [scenario]="scenario"
        ></optima-site-constraint>
      </div>
    </div>
  </div>

  <div>
    <div class="section-header-container">
      <h4 class="section-header">
        {{ 'scenario.tariff_data' | translate }}
      </h4>
    </div>
    <div class="flex flex-col ml-8">
      <div class="section-header-container mt-2">
        <h5 class="section-header tariff-sub-header">
          {{ 'scenario.current_tariff' | translate }}
        </h5>
        <div class="flex flex-col" *ngIf="scenario.currentTariff">
          <div *ngIf="scenario.currentTariff?.filename">
            <span class="filename-label">
              {{ 'scenario.summary.tariff.file_name' | translate }}:
            </span>
            <span>{{ scenario.currentTariff.filename }}</span>
          </div>
          <ng-container *ngIf="scenario.tariffParameters?.dynamicBefore">
            <div
              *ngIf="
                scenario.tariffParameters?.dynamicStoredImportBefore ||
                scenario.currentDynamicImportTariff?.filename
              "
            >
              <span class="filename-label">
                {{
                  'scenario.summary.dynamic_energy_rate.import.file_name'
                    | translate
                }}:
              </span>
              <span
                *ngIf="scenario.tariffParameters?.dynamicStoredImportBefore"
              >
                {{ scenario.tariffParameters.dynamicStoredImportBefore }}
              </span>
              <span *ngIf="scenario.currentDynamicImportTariff?.filename">
                {{ scenario.currentDynamicImportTariff.filename }}
              </span>
            </div>
            <div
              *ngIf="
                scenario.tariffParameters?.dynamicStoredExportBefore ||
                scenario.currentDynamicExportTariff?.filename
              "
            >
              <span class="filename-label">
                {{
                  'scenario.summary.dynamic_energy_rate.export.file_name'
                    | translate
                }}:
              </span>
              <span
                *ngIf="scenario.tariffParameters?.dynamicStoredExportBefore"
              >
                {{ scenario.tariffParameters.dynamicStoredExportBefore }}
              </span>
              <span *ngIf="scenario.currentDynamicExportTariff?.filename">
                {{ scenario.currentDynamicExportTariff.filename }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
      <div
        class="flex flex-col items-end"
        *ngIf="scenario.currentTariff?.stats?.length; else emptyEnergyUse"
      >
        <optima-tariff-table
          [stats]="scenario.currentTariff.stats"
          [projectId]="scenario.parentId"
          class="table-container"
        ></optima-tariff-table>
      </div>

      <ng-container
        *ngIf="scenario?.options?.tariffSwitch && scenario.currentTariff?.id"
      >
        <div class="section-header-container">
          <h5 class="section-header tariff-sub-header">
            {{ 'scenario.switch_tariff' | translate }}
          </h5>
          <div class="flex flex-col" *ngIf="scenario.switchTariff">
            <div *ngIf="scenario.switchTariff?.filename">
              <span class="filename-label">
                {{ 'scenario.summary.tariff.file_name' | translate }}:
              </span>
              <span>{{ scenario.switchTariff.filename }}</span>
            </div>
            <ng-container *ngIf="scenario.tariffParameters?.dynamicAfter">
              <div
                *ngIf="
                  scenario.tariffParameters?.dynamicStoredImportAfter ||
                  scenario.switchDynamicImportTariff?.filename
                "
              >
                <span class="filename-label">
                  {{
                    'scenario.summary.dynamic_energy_rate.import.file_name'
                      | translate
                  }}:
                </span>
                <span
                  *ngIf="scenario.tariffParameters?.dynamicStoredImportAfter"
                >
                  {{ scenario.tariffParameters.dynamicStoredImportAfter }}
                </span>
                <span *ngIf="scenario.switchDynamicImportTariff?.filename">
                  {{ scenario.switchDynamicImportTariff.filename }}
                </span>
              </div>
              <div
                *ngIf="
                  scenario.tariffParameters?.dynamicStoredExportAfter ||
                  scenario.switchDynamicExportTariff?.filename
                "
              >
                <span class="filename-label">
                  {{
                    'scenario.summary.dynamic_energy_rate.export.file_name'
                      | translate
                  }}:
                </span>
                <span
                  *ngIf="scenario.tariffParameters?.dynamicStoredExportAfter"
                >
                  {{ scenario.tariffParameters.dynamicStoredExportAfter }}
                </span>
                <span *ngIf="scenario.switchDynamicExportTariff?.filename">
                  {{ scenario.switchDynamicExportTariff.filename }}
                </span>
              </div>
            </ng-container>
          </div>
        </div>
        <div
          class="flex flex-col items-end"
          *ngIf="scenario.switchTariff?.stats?.length; else emptyEnergyUse"
        >
          <optima-tariff-table
            [stats]="scenario.switchTariff.stats"
            [projectId]="scenario.parentId"
            class="table-container"
          ></optima-tariff-table>
        </div>
      </ng-container>
    </div>
  </div>

  <div>
    <div class="section-header-container">
      <h4 class="section-header" data-automation="summary-page-solar-header">
        {{ 'scenario.solar' | translate }}
      </h4>
    </div>
    <div class="flex flex-col ml-8">
      <div class="flex flex-col section-header-container mt-2">
        <h5
          class="flex items-center section-header solar-pv-sub-header"
          data-automation="summary-page-solar-subheader"
        >
          {{ 'scenario.solar.details' | translate }}
        </h5>
        <div class="flex" *ngIf="checkPvGeneratedBy()">
          <span class="generated-description">
            {{ 'scenario.solar.create.pvwatts.generated_by' | translate }}
          </span>
          <span class="ml-1">
            <a class="pointer" target="_blank" href="https://pvwatts.nrel.gov/">
              {{ 'scenario.solar.create.pvwatts.link.definition' | translate }}
            </a>
          </span>
        </div>
      </div>
      <div class="flex flex-col justify-start subsection-container">
        <ng-container
          *ngIf="
            scenario?.pvloadId && scenario?.options?.solarPv;
            else emptySolar
          "
        >
          <div class="flex items-center">
            <div class="flex flex-col mr-4">
              <span
                class="solar-pv-summary-label mb-4"
                data-automation="solar-system-size-label"
              >
                {{ 'scenario.summary.solar.system_size' | translate }}:
              </span>
              <span
                class="solar-pv-summary-label"
                data-automation="solar-generation-label"
              >
                {{ 'scenario.summary.solar.generation' | translate }}:
              </span>
            </div>
            <div class="flex flex-col">
              <span
                class="chips mb-1"
                data-automation="solar-system-size-value"
              >
                {{ scenario.pvloadProfile?.solarPvSize | number: '1.1-1' }}
                {{ 'kW-DC' | translate }}
              </span>
              <span class="chips" data-automation="solar-generation-value">
                {{ scenario?.pvloadProfile?.stats?.totalKwh | number: '1.0-0' }}
                {{ 'units.kwh' | translate }}
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div>
    <div class="section-header-container">
      <h4 class="section-header">
        {{ 'scenario.energy_storage' | translate }}
      </h4>
    </div>
    <div class="flex flex-col ml-8">
      <div class="section-header-container mt-2">
        <h5 class="section-header ess-sub-header">
          {{ 'scenario.selected_ess' | translate }}
        </h5>
      </div>
      <div class="flex flex-col justify-start">
        <optima-ess-summary-table
          [options]="scenario.options"
          [ders]="scenario.ders"
          [bess]="bess"
        ></optima-ess-summary-table>
      </div>
    </div>
  </div>

  <div>
    <optima-grid-summary [scenario]="scenario"></optima-grid-summary>
  </div>

  <!--  RUN BUTTON -->

  <div>
    <div class="section-header-container"></div>
    <div class="flex items-center justify-center">
      <div
        [popper]="myPopover"
        [popperApplyClass]="'run-popover'"
        [popperDisabled]="resultService.runEnable"
        [popperTrigger]="'hover'"
        [popperPlacement]="'top'"
        [popperDisableStyle]="true"
        [popperHideOnMouseLeave]="true"
        [popperStyles]="{ width: 'fit-content' }"
      >
        <button
          class="flex justify-start items-center full-button"
          mat-ripple
          aria-label="run"
          data-automation="run-button"
          (click)="startRun()"
          [disabled]="!resultService.runEnable"
        >
          <mat-icon
            svgIcon="spin"
            [ngClass]="{ rotating: resultService.showRunningSpinner }"
          ></mat-icon>
          <span class="ml-3">
            {{ 'scenario.button.run_results' | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyEnergyUse>
  <div class="ml-8">
    <span data-automation="no-data-label">
      {{ 'scenario.summary.energy_use.no_data' | translate }}
    </span>
  </div>
</ng-template>

<ng-template #emptySolar>
  <div class="ml-8">
    <span data-automation="no-data-label">
      {{ 'scenario.summary.solar.no_data' | translate }}
    </span>
  </div>
</ng-template>

<popper-content #myPopover>
  <ul>
    <li
      *ngFor="let key of scenarioService.runDisabledTranslationKeys"
      class="list-item"
    >
      <span>{{ key | translate }}</span>
    </li>
  </ul>
</popper-content>
