<div>
  <div class="warning-message flex" *ngIf="areChanges()">
    <div
      class="background-pink flex justify-start items-center p-4 pl-8 w-full"
    >
      <div class="flex items-center banner-big-text-container">
        <mat-icon class="warning-image mr-3 text-error-red">
          sync_problem
        </mat-icon>
        <span
          class="text-bold text-18"
          data-automation="results-not-updated-text"
        >
          {{ 'scenario.results.not_updated' | translate }}
        </span>
      </div>
      <div>
        <span data-automation="results-have-changed-text" class="border-left">
          {{ 'scenario.results.inputs_have_changed' | translate }}
          {{ 'scenario.results.please_select_run' | translate }}
        </span>
      </div>
    </div>
  </div>

  <div class="flex justify-between items-center mt-6 mb-6">
    <div class="flex justify-end items-center">
      <img
        src="../../../../assets/svg-icons/reset-pink.svg"
        alt=""
        class="refresh-icon mr-2"
      />
      <span class="text-warn last-save">
        {{ 'scenario.results.last_completed' | translate }}:
      </span>
      <span class="text-warn" data-automation="last-completed-date">
        {{ lastCompleted }}
      </span>
    </div>

    <div class="flex">
      <button
        class="flex justify-start items-center button mr-2"
        *ngIf="results?.length"
        (click)="openSnackBar()"
        [cdkCopyToClipboard]="copiedData"
        matTooltip="{{ 'scenario.result.copy.tooltip' | translate }}"
        matRipple
      >
        <mat-icon class="mr-2" svgIcon="copy"></mat-icon>

        <span class="ml-1">
          {{ 'scenario.result.copy' | translate }}
        </span>
      </button>

      <button
        class="flex justify-start items-center button"
        mat-ripple
        aria-label="export"
        data-automation="export-results-button"
        *ngIf="results?.length"
        (click)="exportResults()"
      >
        <mat-icon class="mr-2" svgIcon="export-data"></mat-icon>

        <span class="ml-1">
          {{ 'scenario.button.export' | translate }}
        </span>
      </button>
    </div>
  </div>

  <div
    *ngIf="results?.length; else emptyTemplate"
    data-automation="result-table"
    class="table-container-wrapper"
    [ngStyle]="{ 'overflow-y': isFirefox ? 'auto' : 'overlay' }"
  >
    <optima-result-table
      [results]="results"
      [selectedIdx]="idx"
      (copiedDataEmitter)="setDataForCopy($event)"
    ></optima-result-table>
  </div>

  <div
    *ngIf="
      results?.length &&
      results[selectedConfigIndex]?.runState !== RunState.STARTED &&
      !areChanges()
    "
    class="report-container"
    data-automation="result-report-section"
  >
    <div class="report-config-container">
      <h6 class="pl-8">
        {{ 'scenario.results.report.config' | translate }}{{ idx }}
      </h6>

      <button
        class="flex justify-start items-center button white-button mr-8"
        mat-ripple
        aria-label="run-with-forecasted"
        data-automation="run-with-forecasted"
        (click)="runWithForecasted()"
        *ngIf="forecastedService.isThereForecasted(scenario)"
      >
        <mat-icon
          svgIcon="spin"
          [ngClass]="{
            rotating: resultService.showForecastedRunningSpinner,
          }"
        ></mat-icon>

        <span class="ml-1">
          {{ 'scenario.button.run.forecasted' | translate }}
        </span>
      </button>
    </div>

    <div class="report-content">
      <h6 class="savings-and-revenue-title">
        {{ 'scenario.results.savings.revenues' | translate }}
      </h6>

      <div
        class="flex flex-col mb-8"
        [ngClass]="{
          'flex-col': showForecastResults,
        }"
      >
        <optima-savings-revenues-table
          class="mb-8"
          [ngClass]="{
            'self-stretch': showForecastResults,
            'savings-table-no-forecast': !showForecastResults,
            'mr-4': showForecastResults,
          }"
          [result]="results[selectedConfigIndex]"
          [forecastResult]="forecastResult"
          [showForecastResult]="showForecastResults"
        ></optima-savings-revenues-table>
        <div
          class="flex"
          [ngClass]="{
            'justify-between': showForecastResults,
          }"
        >
          <div
            [style.width]="showForecastResults ? '48%' : 'inherit'"
            *ngIf="results[selectedConfigIndex]?.adjustedLoadProfileId"
          >
            <span *ngIf="showForecastResults" class="flex mb-3 tree-map-title">
              {{ 'scenario.results.forecast.historical' | translate }}
            </span>
            <optima-savings-revenues-treemap
              [result]="results[selectedConfigIndex]"
              [chartId]="'savings-revenue-treemap-historical'"
            ></optima-savings-revenues-treemap>
          </div>
          <div
            [style.width]="showForecastResults ? '48%' : 'inherit'"
            *ngIf="showForecastResults"
          >
            <span class="flex mb-3 tree-map-title">
              {{ 'scenario.results.forecast.forecasted' | translate }}
            </span>
            <optima-savings-revenues-treemap
              [result]="forecastResult"
              [chartId]="'savings-revenue-treemap-forecasted'"
            ></optima-savings-revenues-treemap>
          </div>
        </div>
      </div>

      <div
        *ngIf="showForecastError()"
        class="w-full mb-8 failed-forecast-run-section"
        data-automation="failed-forecast-run-message"
      >
        <div
          class="flex items-start margin-left-auto margin-right-auto failed-forecast-run-container"
        >
          <div class="text-center">
            <mat-icon class="text-primary-color failed-forecast-run-icon">
              warning_amber
            </mat-icon>
            <h2 class="failed-forecast-run-header">
              {{ 'loader.message.run.forecast.run.failed' | translate }}
            </h2>
            <span>
              {{ 'loader.message.run.forecast.run.failed.sub' | translate }}
            </span>
          </div>
        </div>
      </div>

      <div
        class="forecast-loader-container"
        [style.visibility]="
          forecastResult?.runState === RunState.STARTED ? 'visible' : 'hidden'
        "
        [style.height]="
          forecastResult?.runState === RunState.STARTED ? '400px' : '0px'
        "
      >
        <optima-loader-no-http
          id="forecast-run-loader"
          [translateString]="'loader.message.run.forecast.run'"
          [translateStringSubtext]="'loader.message.run.forecast.sub'"
        ></optima-loader-no-http>
      </div>

      <mat-button-toggle-group
        appearance="legacy"
        [(ngModel)]="activeMode"
        *ngIf="scenario.options && scenario.options.forecast"
        class="mb-8 mt-4"
        (change)="buttonChange()"
      >
        <mat-button-toggle
          value="historical"
          aria-label="Historical Data"
          class="button-toggle"
          [ngClass]="{ active: activeMode === 'historical' }"
        >
          {{ 'scenario.results.forecast.historical' | translate }}
        </mat-button-toggle>
        <mat-button-toggle
          value="forecasted"
          aria-label="Forecasted Data"
          class="button-toggle"
          [disabled]="!showForecastResults"
          [ngClass]="{ active: activeMode === 'forecasted' }"
        >
          {{ 'scenario.results.forecast.forecasted' | translate }}
        </mat-button-toggle>
      </mat-button-toggle-group>

      <optima-multi-load-profile-chart
        [forecasted]="activeMode !== 'historical'"
        [chartId]="'historical-graph'"
        [projectId]="scenario.parentId"
        [afterSolarJson]="
          scenario?.options?.solarPv && scenario?.options?.energyStorage
            ? scenario.adjustedPvLoadProfile?.json
            : null
        "
        [afterSolarStats]="
          activeMode === 'historical'
            ? scenario?.options?.solarPv && scenario?.options?.energyStorage
              ? scenario.adjustedPvLoadProfile?.stats
              : null
            : forecastAdjusted?.stats
        "
        [beforeJson]="scenario.loadProfile?.json"
        [beforeStats]="scenario.loadProfile?.stats"
        [scenario]="scenario"
        [scenarioLabel]="scenario.displayLabel"
        [projectLabel]="project.displayLabel"
        [mode]="'result'"
        [beforeColor]="'#C2185B'"
        [afterEssColor]="'#043B9F'"
        [afterSolarColor]="'#F06705'"
        [stateOfChargeColor]="'#789E10'"
      ></optima-multi-load-profile-chart>

      <optima-result-billing-table
        [forecasted]="activeMode !== 'historical'"
        [projectId]="project.id"
        [scenarioLabel]="scenario.displayLabel"
        [projectLabel]="project.displayLabel"
        [beforeStats]="scenario.loadProfile?.stats"
        [beforeCharges]="
          scenario.currentTariff?.stats[scenario.currentTariff.stats.length - 1]
        "
        [afterTariffCharges]="
          scenario?.options?.tariffSwitch && scenario.switchTariff
            ? scenario.switchTariff?.stats[
                scenario.switchTariff.stats.length - 1
              ]
            : null
        "
        [afterSolarStats]="
          scenario?.options?.solarPv && scenario?.options?.energyStorage
            ? scenario.adjustedPvLoadProfile?.stats
            : null
        "
        [afterSolarCharges]="afterSolarCharges"
        [resultsCharges]="
          activeMode === 'historical'
            ? resultsCharges
            : resultsChargesForecasted
        "
        [scenario]="scenario"
      ></optima-result-billing-table>
      <div class="grid-services-results-table-container">
        <optima-grid-services-results-table
          *ngIf="idx >= 0"
          [scenario]="scenario"
          [idx]="idx"
          [forecasted]="activeMode !== 'historical'"
        ></optima-grid-services-results-table>
      </div>
    </div>
  </div>

  <div
    *ngIf="areChanges()"
    class="w-full results-changed-section"
    data-automation="results-changed-section"
  >
    <div
      class="flex items-start margin-left-auto margin-right-auto results-changed-container"
    >
      <mat-icon class="text-primary-color results-changed-icon">
        sync_problem
      </mat-icon>
      <div>
        <h2
          class="results-changed-header"
          data-automation="results-changed-header"
        >
          {{ 'scenario.results.inputs_have_changed' | translate }}
        </h2>
        <span
          class="results-changed-sub-header"
          data-automation="results-changed-text"
        >
          {{ 'scenario.results.please_select_run' | translate }}
        </span>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyTemplate>
  <div
    class="flex flex-col justify-start items-center"
    *ngIf="!this.resultService.showRerunningSpinner"
  >
    <mat-icon class="no-data-icon" svgIcon="no-data"></mat-icon>
    <h1 data-automation="no-results-header">
      {{ 'scenario.results.no_results' | translate }}
    </h1>
    <span data-automation="no-results-description">
      {{ 'scenario.results.no_results.description' | translate }}
    </span>
  </div>
  <div
    class="flex flex-col justify-start items-center"
    *ngIf="this.resultService.showRerunningSpinner"
  >
    <mat-icon svgIcon="spin-purple" class="rotating spin-icon"></mat-icon>
    <h1>{{ 'scenario.results.running' | translate }}</h1>
  </div>
</ng-template>
