<div>
  <div class="external-container" *ngIf="scenario.pvloadProfile">
    <div class="flex flex-col mb-6">
      <h6
        class="flex items-center mb-1"
        data-automation="summary-page-solar-subheader"
      >
        {{ 'scenario.solar.details' | translate }}
      </h6>
      <div class="flex" *ngIf="generatedByPvWatts">
        <span class="generated-description">
          {{ 'scenario.solar.create.pvwatts.generated_by' | translate }}
        </span>
        <span class="ml-1">
          <a class="pointer" target="_blank" href="https://pvwatts.nrel.gov/">
            {{ 'scenario.solar.create.pvwatts.link.definition' | translate }}
          </a>
        </span>
      </div>
    </div>
    <div class="flex justify-between items-center internal-container">
      <div class="system-grid">
        <div>
          <label class="text-bold">
            {{ 'scenario.solar.system_size.details' | translate }}:
          </label>
          <label>
            {{ scenario.pvloadProfile?.solarPvSize | number: '1.1-1' }}
          </label>
        </div>
        <div *ngIf="generatedByPvWatts">
          <label class="text-bold">
            {{ 'scenario.solar.create.form.loss.title' | translate }}:
          </label>
          <label>
            {{ scenario.pvloadProfile?.pvParams?.loss | number: '1.1-1' }}
          </label>
        </div>
        <div *ngIf="generatedByPvWatts">
          <label class="text-bold">
            {{ 'scenario.solar.create.form.moduleType.title' | translate }}:
          </label>
          <label>{{ moduleTypeToDisplay }}</label>
        </div>
        <div *ngIf="generatedByPvWatts">
          <label class="text-bold">
            {{ 'scenario.solar.create.form.tilt.title' | translate }}:
          </label>
          <label>
            {{ scenario.pvloadProfile?.pvParams?.tilt | number: '1.1-1' }}
          </label>
        </div>
        <div *ngIf="generatedByPvWatts">
          <label class="text-bold">
            {{ 'scenario.solar.create.form.arrayType.title' | translate }}:
          </label>
          <label>{{ arrayTypeToDisplay }}</label>
        </div>
        <div *ngIf="generatedByPvWatts">
          <label class="text-bold">
            {{ 'scenario.solar.create.form.azimuth.title' | translate }}:
          </label>
          <label>
            {{ scenario.pvloadProfile?.pvParams?.azimuth | number: '1.1-1' }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="flex flex-col flex-wrap"
  [ngClass]="scenario.pvloadId ? 'items-start' : 'items-stretch'"
>
  <div *ngIf="!scenario.pvloadId" class="flex flex-col">
    <label id="radio-group-label">
      {{ 'scenario.solar.radio.description' | translate }}
    </label>
    <mat-radio-group
      aria-labelledby="radio-group-label"
      class="mb-5"
      [(ngModel)]="radioChoice"
      (change)="radioChange($event)"
    >
      <mat-radio-button
        class="mr-3"
        data-automation="solar-pv-existing-radio-btn"
        [value]="0"
      >
        {{ 'scenario.solar.radio.existing' | translate }}
      </mat-radio-button>
      <mat-radio-button
        class="mr-3"
        data-automation="solar-pv-upload-radio-btn"
        [value]="1"
      >
        {{ 'scenario.solar.radio.upload' | translate }}
      </mat-radio-button>
      <mat-radio-button data-automation="solar-pv-create-radio-btn" [value]="2">
        {{ 'scenario.solar.radio.create' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div
    data-automation="select-existing-pv-loads"
    class="flex flex-col existing-profile-container"
    *ngIf="!scenario.pvloadId && !showCreate"
    [hidden]="radioChoice !== 0 && !scenario.pvloadId"
  >
    <div class="flex items-center search-container">
      <mat-icon svgIcon="search-new" class="icon-search"></mat-icon>
      <input
        class="search-input"
        placeholder="{{ 'project.search' | translate }}"
        [disabled]="!loadProfiles?.length"
        #searchInput
      />
    </div>
    <div class="load-profiles-container" *ngIf="loadProfiles?.length">
      <div
        class="flex items-center"
        *ngFor="let profile of filteredProfiles"
        (click)="updateLoadProfile(profile)"
        (mouseenter)="profileInHover = profile"
      >
        <input type="radio" name="profile" />
        <label class="load-profile-text" *ngIf="profile.filename">
          {{ profile.filename | trimFileExtension | stringTruncate }}
        </label>
        <img
          src="../../../../../../assets/svg-icons/general-info.svg"
          class="pop-icon"
          [popper]="myPopover"
          [popperTrigger]="'hover'"
          [popperPlacement]="'right'"
          [popperDisableStyle]="true"
          [popperDisableAnimation]="true"
          [popperHideOnMouseLeave]="true"
          [popperStyles]="{ width: 'min-content' }"
          alt="general-info"
        />
      </div>
    </div>
    <div class="load-profiles-container" *ngIf="!loadProfiles?.length">
      <p class="text-light-color">
        {{ 'scenario.solar.no_files_found' | translate }}
      </p>
    </div>
  </div>
  <div
    class="uploader-container"
    [ngClass]="{
      'uploader-container-width': !scenario.pvloadId,
      'fix-margin': scenario.pvloadProfile?.solarPvSize
    }"
    *ngIf="!showCreate"
    [hidden]="radioChoice !== 1 && !scenario.pvloadId"
  >
    <div class="flex flex-col mb-6" *ngIf="!scenario.pvloadProfile">
      <mat-form-field
        data-automation="solar-pv-system-size-field"
        class="mt-4 mb-4"
        appearance="outline"
      >
        <mat-label>{{ 'scenario.solar.system_size' | translate }}</mat-label>
        <input
          matInput
          type="number"
          name="name"
          [(ngModel)]="solarSystemSize"
          (ngModelChange)="setFormDataParameters()"
          placeholder="{{
            'scenario.solar.system_size.placeholder' | translate
          }}"
          required
        />
        <mat-hint
          class="mat-error"
          *ngIf="solarSystemSize <= 0 || solarSystemSize >= 1000000"
        >
          {{ 'scenario.form.solar.system_size.range.warning' | translate }}
        </mat-hint>
      </mat-form-field>
    </div>
    <optima-uploader
      title="{{ 'uploader.solar_load_profile.title' | translate }}"
      fileLabel="{{ 'uploader.solar_profile.file_label' | translate }}"
      [url]="scenarioService.apiUrl + '/scenarios/' + scenario.id + '/pv-load'"
      [createMethod]="'post'"
      [accept]="'.csv'"
      [object]="scenario.pvloadProfile"
      (objectChange)="uploaderCallback($event)"
      [formDataParameters]="formDataParameters"
      [idField]="'id'"
      [labelField]="'filename'"
      [disabled]="solarSystemSize <= 0 || solarSystemSize >= 1000000"
    ></optima-uploader>
    <div class="flex flex-col" *ngIf="scenario.pvloadId">
      <div
        id="solar-error-message-container"
        class="flex error-message-container"
        *ngIf="!scenario.loadprofileId"
      >
        <img
          class="mr-3 mt-1 deny-icon"
          src="../../../../../../assets/svg-icons/deny-pink.svg"
          alt="deny"
        />
        <div class="flex flex-wrap items-center mr-4">
          <span class="text-bold mr-3 energy-use-error">
            {{ 'scenario.solar.error.missing_energy_use.title' | translate }}
          </span>
          <div id="solar-error-message-divider" class="divider"></div>
          <span>
            {{
              'scenario.solar.error.missing_energy_use.description' | translate
            }}
          </span>
        </div>
      </div>
      <optima-multi-load-profile-chart
        *ngIf="
          scenario.loadprofileId &&
          scenario.adjustedPvLoadProfile?.stats &&
          scenario.adjustedPvLoadProfile?.json
        "
        [projectId]="scenario.parentId"
        [beforeJson]="scenario.loadProfile.json"
        [beforeStats]="scenario.loadProfile.stats"
        [afterSolarJson]="scenario.adjustedPvLoadProfile.json"
        [afterSolarStats]="scenario.adjustedPvLoadProfile.stats"
        [mode]="'form'"
        [beforeColor]="'#C2185B'"
        [afterSolarColor]="'#F06705'"
        [chartId]="'chart-id-2'"
        [scenario]="scenario"
      ></optima-multi-load-profile-chart>
      <optima-multi-load-profile-stats-table
        *ngIf="
          scenario.loadprofileId &&
          scenario.adjustedPvLoadProfile
        "
        [mode]="'form'"
        [beforeStats]="scenario.loadProfile?.stats"
        [afterSolarStats]="scenario.adjustedPvLoadProfile?.stats"
        [scenarioOptions]="scenario.options"
      ></optima-multi-load-profile-stats-table>
    </div>
  </div>
  <div
    class="flex flex-col"
    [hidden]="scenario.pvloadId && radioChoice !== 2"
    [ngClass]="{ 'w-full': showCreate }"
  >
    <div
      class="flex justify-between items-center flex-wrap create-solar-header"
      *ngIf="showCreate"
    >
      <div class="flex">
        <span class="create-description">
          {{ 'scenario.solar.create.pvwatts.link.description' | translate }}
        </span>
        <span class="ml-1">
          <a
            class="pointer"
            target="_blank"
            data-automation="pvwatts-hyperlink"
            href="https://pvwatts.nrel.gov/"
          >
            {{ 'scenario.solar.create.pvwatts.link.definition' | translate }}
          </a>
        </span>
      </div>
      <div
        class="flex"
        [matTooltip]="
          createSolarComponent?.formGroup?.invalid ||
          createSolarComponent?.monthFormGroup?.invalid
            ? tooltipMessage
            : null
        "
        [matTooltipDisabled]="
          createSolarComponent?.formGroup?.valid &&
          createSolarComponent?.monthFormGroup?.valid
        "
      >
        <button
          class="flex justify-center items-center full-button"
          (click)="createSolarComponent.submit()"
          [disabled]="
            createSolarComponent?.formGroup?.invalid ||
            createSolarComponent?.monthFormGroup?.invalid
          "
          mat-ripple
        >
          <mat-icon svgIcon="apply-white" class="mr-2"></mat-icon>
          <span>{{ 'scenario.solar.create.button.submit' | translate }}</span>
        </button>
      </div>
    </div>
    <optima-create-solar
      *ngIf="showCreate"
      [scenario]="scenario"
      (created)="showGraph($event)"
    ></optima-create-solar>
  </div>
</div>

<popper-content #myPopover>
  <optima-load-profile-table
    *ngIf="profileInHover"
    [columns]="solarProfileColumns"
    [stats]="profileInHover?.stats"
    [onHover]="true"
  ></optima-load-profile-table>
</popper-content>
