<div [ngClass]="{ 'external-chart-container': mode !== 'single' }">
  <div
    class="section-header-container mt-0 mb-0 pb-0"
    *ngIf="mode === 'result'"
  >
    <h6 class="section-header">
      {{ 'scenario.results.dispatch_profile' | translate }}
    </h6>
    <div
      class="flex items-center download-profile-container"
      [formGroup]="downloadDispatchProfileTypeForm"
    >
      <span
        class="icon-container h-full flex items-center download-profile-link"
      >
        <a
          class="pointer"
          data-automation="download-profile-hyperlink"
          (click)="downloadDispatchProfile()"
        >
          <mat-icon svgIcon="download-new" class="header-icon"></mat-icon>
          <span>
            {{ 'scenario.results.download_dispatch_profile' | translate }}
          </span>
        </a>
      </span>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'scenario.results.download_dispatch_profile_type' | translate }}
          <mat-icon
            svgIcon="info"
            [matTooltip]="
              translateService.instant(
                'scenario.results.download_dispatch_profile_type_tooltip'
              )
            "
          ></mat-icon>
        </mat-label>
        <mat-select
          data-automation="download-dispatch-profile-type"
          placeholder="{{
            'scenario.results.selected_config_all_iterations' | translate
          }}"
          (opened)="addCssForSelect()"
          (closed)="removeCssForSelect()"
          formControlName="downloadDispatchProfileType"
        >
          <mat-option [value]="DOWNLOAD_TYPES.ONE_ITERATION">
            {{ 'scenario.results.selected_config_1_iteration' | translate }}
          </mat-option>
          <mat-option [value]="DOWNLOAD_TYPES.ALL_ITERATIONS">
            {{ 'scenario.results.selected_config_all_iterations' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <h6 *ngIf="mode === 'form'">{{ 'scenario.adjusted' | translate }}</h6>
  <div
    [ngClass]="{
      'chart-wrapper': mode !== 'single',
      'flex-programs': events?.length,
    }"
  >
    <div class="chart-loader-container">
      <optima-loader-no-http
        id="{{ chartId }}-loader"
        translateString="chart.building"
      ></optima-loader-no-http>
      <div [id]="chartId" data-automation="load-profile-chart"></div>
    </div>
    <div class="programs-container" *ngIf="chart && events?.length">
      <div
        (click)="toggleAllEvents()"
        class="flex items-center pointer"
        [ngClass]="{ 'disabled-legend': areAllProgramsHidden() }"
      >
        <div class="all-programs-line mr-3"></div>
        {{ 'scenario.results.grid.all' | translate }}
      </div>
      <div
        *ngFor="let programName of eventsProgramsDistinct(); let i = index"
        (click)="toggleEvent(programName)"
        [ngClass]="{ 'disabled-legend': !isPlotShow(programName) }"
        class="flex items-center pointer"
      >
        <div
          class="colored-square mr-3"
          [class]="'background-color-' + (i + 1)"
        ></div>
        {{ programName }}
      </div>
    </div>
  </div>
</div>

<div class="flex items-center" id="datepickers" #datepickers>
  <div class="datepicker-container" (click)="picker.open()">
    <mat-date-range-picker
      #picker
      (closed)="closedPicker()"
    ></mat-date-range-picker>
    <mat-date-range-input
      class="date-input"
      [rangePicker]="picker"
      [min]="minDate"
      [max]="maxDate"
    >
      <input matStartDate [(ngModel)]="dateFrom" />
      <input matEndDate [(ngModel)]="dateTo" />
    </mat-date-range-input>
    <span>
      {{
        timezoneService.getDateTimeDisplayLabelForChart(
          dateFrom?.toString(),
          'll z',
          timezone
        )
      }}
    </span>
    <div class="flex items-center ml-3 mr-3">
      <img
        [style.visibility]="dpCreated ? 'visible' : 'hidden'"
        width="20"
        height="20"
        src="assets/images/arrow-right.png"
        alt="arrow-right"
      />
    </div>
    <span>
      {{
        timezoneService.getDateTimeDisplayLabelForChart(
          dateTo?.toString(),
          'll z',
          timezone
        )
      }}
    </span>
  </div>
</div>
