import { Environment } from './environment.interface';
export { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  brands: {
    enelx: {
      baseUrl: '/',
    },
  },
  defaults: {
    brand: 'enelx',
    locale: 'en-us',
  },
  apiKey: '',
};
