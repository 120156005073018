<div *ngIf="scenario.tariffParameters" class="flex options-container">
  <mat-checkbox
    [(ngModel)]="scenario.tariffParameters.nemNetting"
    (ngModelChange)="update()"
  >
    {{ 'scenario.tariff.nem' | translate }}
  </mat-checkbox>
  <img
    class="info-circle"
    matTooltip="{{ 'scenario.form.tariff.nem_netting' | translate }}"
    src="../../../../../../assets/svg-icons/info-circle.svg"
    alt="info-circle"
    height="14"
    width="14"
  />
</div>
