import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TariffAdvancedOptionsComponent } from './tariff-advanced-options.component';
import { TariffModule } from '@component';
import { DirectiveModule } from '@directive';
import { PipeModule } from '@pipe';

@NgModule({
  declarations: [TariffAdvancedOptionsComponent],
  exports: [TariffAdvancedOptionsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSliderModule,
    FormsModule,
    MatRadioModule,
    MatInputModule,
    MatTooltipModule,
    PipeModule,
    DirectiveModule,
    ReactiveFormsModule,
    TariffModule,
  ],
})
export class TariffAdvancedOptionsModule {}
