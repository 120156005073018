<div
  class="flex items-center"
  [ngClass]="{ 'flex-col': dynamicInputOptions.input?.layout === 'vertical' }"
>
  <div
    [class]="
      dynamicInputOptions.input?.layout === 'vertical'
        ? 'mb-4'
        : 'mr-4 flex items-center'
    "
  >
    <p class="pointer" [ngStyle]="{ minWidth: minWidth }">
      {{ dynamicInputOptions.displayLabel }}
    </p>
    <img
      *ngIf="dynamicInputOptions.tooltip"
      (click)="stopClick($event)"
      class="pop-icon"
      matTooltip="{{ dynamicInputOptions.tooltip }}"
      src="../../../../../assets/svg-icons/general-info.svg"
      alt="general-info"
    />
  </div>
  <div
    class="ml-4 form-field-container"
    [hidden]="hideInputField"
    (click)="stopClick($event)"
  >
    <mat-form-field *ngIf="dynamicInputOptions.input" appearance="outline">
      <mat-label>
        {{
          dynamicInputOptions.input.label ??
            dynamicInputOptions.input.placeholder
        }}
      </mat-label>
      <input
        matInput
        [type]="dynamicInputOptions.input.type"
        [errorStateMatcher]="matcher"
        [formControl]="formControl"
        [placeholder]="dynamicInputOptions.input.placeholder"
        #matInput
      />
    </mat-form-field>
    <mat-error
      *ngIf="formControl.invalid || formControl.status === 'INVALID'"
      class="flex flex-col"
    >
      <span>{{ error }}</span>
      <span *ngIf="previousValue && showPreviousValueError">
        {{
          'dynamic_input.error.previous_value'
            | translate: { previousValue: previousValue }
        }}
      </span>
    </mat-error>
  </div>
</div>
