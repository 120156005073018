import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxGlobalContactFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';
import { AppComponent } from './app.component';
import { AlertModule } from './shared/component/global/alert/alert.module';
import { LoaderModule } from './shared/component/global/loader/loader.module';
import { TreeModule } from '@component';
import { CoreModule } from '@core';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    TreeModule,
    AlertModule,
    NgxGlobalContactFooterModule.forRoot({
      namespace: 'ngx-global-contact-footer.',
    }),
    GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    LoaderModule,
    AlertModule,
  ],
  exports: [BrowserModule, BrowserAnimationsModule, CoreModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
